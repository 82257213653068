import { Collapse } from "@arco-design/web-react";
import { t } from "easy-email-pro-core";
import React from "react";
import { AttributesPanelWrapper, CollapseWrapper, AttributeField, ResponsiveField, ResponsiveTabs, } from "easy-email-pro-theme";
import { ActiveTabKeys, useSelectedNode } from "easy-email-pro-editor";

export const buttonWidthAdapter = {
  formatter(val) {
    return val === "100%" ? "100%" : "auto";
  },
  normalize(val) {
    return val === "100%" ? "100%" : undefined;
  },
};

export const CustomBlockPanel = ({ nodePath }) => {
  return (
    <AttributesPanelWrapper>
      <ResponsiveTabs
        desktop={
          <AttributesContainer
            mode={ActiveTabKeys.DESKTOP}
            nodePath={nodePath}
          />
        }
        mobile={
          <AttributesContainer
            mode={ActiveTabKeys.MOBILE}
            nodePath={nodePath}
          />
        }
      />
    </AttributesPanelWrapper>
  );
};

function AttributesContainer({
  nodePath,
  mode,
}) {
  const { selectedNode } = useSelectedNode();

  if (!selectedNode) return null;

  return (
    <CollapseWrapper defaultActiveKey={["0", "1", "2", "3", "4", "5"]}>
      <Collapse.Item name="0" header={t("Title")}>
        <AttributeField.TextField
          path={nodePath}
          name="data.content"
          label={t("Title")}
        />
        {/* <AttributeField.Link path={nodePath} name="attributes.href" /> */}
      </Collapse.Item>
      <Collapse.Item name="1" header={t("Add to Calendar")}>
        <AttributeField.TextField
          label={t("Title")}
          name="data.location"
          path={nodePath}
          placeholder="Title"
          className="form-control"
        />
        <AttributeField.TextField
          label={t("Start date")}
          name="data.startDate"
          path={nodePath}
          className="form-control"
          type="date"
        />
        <AttributeField.TextField
          label={t("Start time")}
          name="data.startTime"
          path={nodePath}
          className="form-control"
          type="time"
        />
        <AttributeField.TextField
          label={t("End date")}
          name="data.endDate"
          path={nodePath}
          className="form-control"
          type="date"
        />
        <AttributeField.TextField
          label={t("End time")}
          name="data.endTime"
          path={nodePath}
          className="form-control"
          type="time"
        />
        <AttributeField.TextAreaField
          label={t("Details")}
          name="data.details"
          path={nodePath}
          className="form-control"
        />
      </Collapse.Item>
      <Collapse.Item name="2" header={t("Dimension")}>
        <ResponsiveField
          label={t("Width")}
          component={AttributeField.ButtonGroupField}
          path={nodePath}
          name="width"
          options={[
            { label: t("Auto"), value: "auto" },
            { label: t("Full width"), value: "100%" },
          ]}
          formItem={buttonWidthAdapter}
        />
        <ResponsiveField
          component={AttributeField.Height}
          path={nodePath}
          name="height"
        />
        <ResponsiveField
          label={t("Inner padding")}
          fieldName="inner-padding"
          component={AttributeField.Padding}
          path={nodePath}
          name=""
        />
        <ResponsiveField
          component={AttributeField.Padding}
          path={nodePath}
          name=""
        />
      </Collapse.Item>
      <Collapse.Item name="3" header={t("Typography")}>
        <ResponsiveField
          mode={mode}
          component={AttributeField.Buttons}
          path={nodePath}
          name=""
          type={selectedNode.type}
          hidePadding
        />
      </Collapse.Item>
      <Collapse.Item name="4" header={t("Border")}>
        <ResponsiveField
          component={AttributeField.Border}
          path={nodePath}
          name=""
        />
        <ResponsiveField
          component={AttributeField.BorderRadius}
          path={nodePath}
          name="border-radius"
        />
      </Collapse.Item>
      <Collapse.Item name="5" header={t("Block")}>
        <ResponsiveField
          component={AttributeField.BackgroundColor}
          path={nodePath}
          name="container-background-color"
        />
        <ResponsiveField
          component={AttributeField.TextAlign}
          path={nodePath}
          name={`align`}
        />
      </Collapse.Item>
    </CollapseWrapper>
  );
}
