import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import VerifyAuth from "../pages/VerifyAuth";
import NoAuth from "../pages/NoAuth";
import Communicate from "../pages/Communicate";
import TinyMice from "../pages/TinyMice";
import { getToStoreData } from "../plugins/storage";
import Calendar from "../pages/Calendar";
import TemplateList from "../pages/TemplateList";
import UserList from "../pages/UserList";
import RSVP from "../pages/RSVP";
import RSVPList from "../pages/RSVPList";

const ProtectedRoute = () => {
  const token = getToStoreData("auth-token");

  return token ? <Outlet /> : <Navigate to="/unauthorized" replace />;
};

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/unauthorized" />,
  },
  {
    path: "/unauthorized",
    element: <NoAuth />,
  },
  {
    path: "/verify/:token",
    element: <VerifyAuth />
  },
  {
    path: "/calendar",
    element: <Calendar />
  },
  {
    path: "/RSVP",
    element: <RSVP />
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: "/editor",
        element: <TinyMice />
      },
      {
        path: "/communicate",
        element: <Communicate />
      },
      {
        path: "/templates",
        element: <TemplateList />
      },
      {
        path: "/user-list",
        element: <UserList />
      },
      {
        path: "/RSVP-list",
        element: <RSVPList />
      }
    ]

  },

]);

export default routes;
