import React, { useEffect, useState } from 'react'
import { addToStoreData, getToStoreData } from '../plugins/storage';

const SelectGroupModal = ({ handleGroupSelect, showModal }) => {

  const [groupSelected, setGroupSelected] = useState(getToStoreData("selectedGroup") ?? "");
  const [groupData, setGroupData] = useState([]);

  useEffect(() => {
    const storedData = getToStoreData('groupData');
    if (storedData) {
      setGroupData(JSON.parse(storedData));
    } else {
      setGroupData([]);
    }
  }, []);

  useEffect(() => {
    if (groupData.length === 1) {
      handleGroupSelect(groupData[0])
      addToStoreData("selectedGroup", JSON.stringify(groupData[0]));
    } else if (!getToStoreData("selectedGroup") && groupData.length > 0) {
      showModal()
    }
  },[groupData])

  const handleGroup = (e) => {
    setGroupSelected(e.target.value);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (!groupSelected) return;
    handleGroupSelect(JSON.parse(groupSelected))
    addToStoreData("selectedGroup", groupSelected);
  }

  return (
    <>
      <div className="modal fade group-modal" id="group-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">Group</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form onSubmit={onSubmit}>
                <select className="form-select" value={groupSelected} onChange={handleGroup}>
                  <option disabled value="">Select group</option>
                  {groupData.map((curItem) => (
                    <option key={curItem.id} value={JSON.stringify(curItem)}>{curItem.name}</option>
                  ))}
                </select>
                <div className="mt-3 text-end">
                  <button type="submit" className="btn btn-primary">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default SelectGroupModal
