import React, { useEffect, useRef, useState } from 'react';
import { getToStoreData } from '../plugins/storage';
import axiosInstance from '../plugins/axios';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import * as bootstrap from 'bootstrap/dist/js/bootstrap';

const SentTestModal = ({ activeTab, setActiveTab }) => {
  const [groupAdmin, setGroupAdmin] = useState([]);
  const [email, setEmail] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);

  const openModal = () => {
    setModalVisible(true);
    setActiveTab("test")
    document.body.classList.add('overflow-hidden');
  };

  const closeModal = () => {
    setModalVisible(false)
    setActiveTab("");
    setEmail("");
    setGroupAdmin([]);
    document.body.classList.remove('overflow-hidden');
  };

  const getData = () => {
    setEmail("");
    const selectGroupData = getToStoreData('selectedGroup');

    if (!selectGroupData) return;

    axiosInstance.get(`/group-admins/${JSON.parse(selectGroupData).id}`, {
      headers: {
        'Authorization': `Bearer ${getToStoreData("auth-token")}`
      }
    }).then((response) => {
      if (response.data.length !== 0) {
        setGroupAdmin(response.data.flat());
      }
    }).catch((error) => {
      console.log(error, "network error");
    });
  };

  useEffect(() => {
    if (isModalVisible) {
      getData();
    }
  }, [isModalVisible]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const selected_admin_emails = groupAdmin.filter((user) => {
      return user.user_status;
    }).map((data) => data.user_email);

    const communication_uuid = getToStoreData("communication_uuid");
    const communicate_data = getToStoreData("communicate_data");
    const selectGroupData = getToStoreData('selectedGroup');

    if (!email && selected_admin_emails.length === 0) {
      toast.error("Please select a user or provide email address(s)");
      return;
    };

    if (!selectGroupData) return;
    if (!communicate_data) return;

    axiosInstance.post(`/send-test-email`, {
      body: JSON.parse(communicate_data).email_body ? JSON.parse(JSON.parse(communicate_data).email_body) : "",
      email: email,
      communication_uuid,
      group_id: JSON.parse(selectGroupData).id,
      selected_admin_emails: selected_admin_emails,
      name: JSON.parse(communicate_data).title,
      subject: JSON.parse(communicate_data).title
    }, {
      headers: {
        'Authorization': `Bearer ${getToStoreData("auth-token")}`
      }
    }).then((response) => {
      closeModal();
      const successModalReference = new bootstrap.Modal(document.getElementById('success-modal'));
      document.getElementById("success-message").innerText = "Test email has been successfully sent"
      successModalReference.show();
    }).catch((error) => {
      console.log(error, "network error");
      toast.error(error.response ? error.response.data.message : error.message)
    })
  }

  const handleCheckboxChange = (id) => {
    setGroupAdmin((prevGroupAdmin) =>
      prevGroupAdmin.map((data) =>
        data.ID === id ? { ...data, user_status: !data.user_status } : data
      )
    );
  };

  const modalRef = useRef(null);

  useEffect(() => {
    // Function to handle outside click
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal(); // Close modal if clicked outside the modal content
      }
    };

    // Attach event listener to detect clicks
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Cleanup event listener when component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <button type="button" className={`btn ${activeTab === "test" ? "active" : ""} tab-btn`} onClick={openModal}><img src="/images/popup-icon.png" alt="" className='img-fluid' width={"25px"} />Send test</button>
      {isModalVisible &&
        <Wrapper className="modal fade show" style={{ display: 'block', backgroundColor: "rgb(25 28 28 / 50%)" }} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog" ref={modalRef} >
            <div className="modal-content" style={{ marginTop: "185px", marginLeft: "140px" }}>
              <div className="modal-header modal-custom-header">
                <h3 className="modal-title fs-5">Send a test email</h3>
                {/* <button type="button" className="btn-close" onClick={closeModal}></button> */}
              </div>
              <div className="modal-body my-1">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>Send a test to</label>
                    <input type="text" className="form-control w-100" value={email} onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  <p className="note">Use commas to separate multiple emails or select a checkbox for each user from your database below.</p>
                  <hr />
                  {groupAdmin.map((data) => (
                    <div className="form-check d-flex align-items-center gap-2" key={data.ID}>
                      <input
                        className="form-check-input mt-0"
                        type="checkbox"
                        checked={data.user_status}
                        id={data.user_email}
                        onChange={() => handleCheckboxChange(data.ID)}
                      />
                      <label className="form-check-label" htmlFor={data.user_email} style={{ cursor: "pointer" }}>
                        {data.display_name}
                      </label>
                    </div>
                  ))}
                  <div className="mt-3 text-end">
                    <button type="submit" className="btn btn-primary">Send test</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Wrapper>}
    </>
  );
};

export default SentTestModal;

const Wrapper = styled.div`
  .form-control:focus {
    box-shadow: unset !important;
  }

  .form-check-input:focus {
    box-shadow: unset !important;
  }

  .note {
    padding: 10px 0 3px !important;
    margin-bottom: 0 !important;
    font-size: 14px;
    color: #c5c5c5;
  }
`;
