import { createCustomBlock, t, mergeBlock } from "easy-email-pro-core";
import React from "react";
import { ElementCategory } from "easy-email-pro-core";

export const CustomReportBlockType = {
  LOGO: "Reports",
};

const defaultData = {
  data: {
    reportData: []
  },
  attributes: {
    "padding-top": "10px",
    "padding-bottom": "10px",
    "padding-right": "20px",
    "padding-left": "20px",
    "background-color": "#ffffff",
    "color": "#4a90e2",
    "font-size": "13px",
  }
};

export const CustomReportBlock = createCustomBlock({
  get name() {
    return t("Reports");
  },
  defaultData: defaultData,
  type: CustomReportBlockType.LOGO,
  void: true,
  create: (payload) => {
    const data = {
      type: CustomReportBlockType.LOGO,
      data: {
        ...defaultData.data,
      },
      attributes: {
        ...defaultData.attributes,
      },
      children: [],
    };
    return mergeBlock(data, payload);
  },
  category: ElementCategory.TEXT_LIST,
  render(params) {
    const { node } = params;
    const { data, attributes } = node;

    return (
      <mj-section
        padding-bottom={attributes["padding-bottom"]}
        padding-top={attributes["padding-top"]}
        padding-left={attributes["padding-left"]}
        padding-right={attributes["padding-right"]}
        background-color={attributes["background-color"]}
      >
        {data.reportData.filter(curElem => curElem.isCheck).map((val, index) => {
          return (
            <mj-section key={index} padding="0" padding-bottom="10px" padding-left="16px">
              <mj-group>
                <mj-column width="5%">
                  <mj-image
                    height="16px"
                    width="16px"
                    src={val.icon}
                    padding="0"
                    alt={val.name}
                  />
                </mj-column>
                <mj-column width="95%">
                  <mj-text
                    color={attributes.color}
                    font-size={attributes["font-size"]}
                    padding="0 0 0 10px"
                    line-height="16px"
                    css-class="text-block"
                  >
                    <a
                      href={val.file}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none", color: attributes.color }}
                    >
                      {val.name}
                    </a>
                  </mj-text>
                </mj-column>
              </mj-group>
            </mj-section>
          )
        })}
      </mj-section>
    );
  },
});