import { Collapse } from "@arco-design/web-react";
import { t } from "easy-email-pro-core";
import React, { useEffect, useMemo, useState } from "react";
import { AttributeField, AttributesPanelWrapper, CollapseWrapper, ResponsiveField, useEditorContext, } from "easy-email-pro-theme";
import axios from "../../../../plugins/axios";
import { getToStoreData } from "../../../../plugins/storage";
import { useSelectedNode } from "easy-email-pro-editor";


export const CustomReportBlockPanel = ({ nodePath }) => {
  const [document, setDocument] = useState([]);

  const { setFieldValue } = useEditorContext();
  const { selectedNode } = useSelectedNode();

  const handleCheckbox = (event, id) => {
    console.log(event.target.checked);
    const updatedDocument = document.map((val) => {
      if (val.id === id) {
        return { ...val, isCheck: event.target.checked }
      }
      return val
    })
    setDocument(updatedDocument);
    setFieldValue(nodePath, "data.reportData", updatedDocument);
  }

  const selectedIds = useMemo(
    () => selectedNode?.data.reportData || [],
    [selectedNode]
  );

  const getReports = () => {
    const selectGroup = getToStoreData('selectedGroup');

    if (selectGroup) {
      axios.get(`/group-results-document/${JSON.parse(selectGroup).id}`).then((result) => {
        console.log('result: ', result)
        if (result.data.documents.length > 0) {
          const updatedDocument = result.data.documents.map((val) => {
            return { ...val, isCheck: true }
          })
          setDocument(updatedDocument);
          setFieldValue(nodePath, "data.reportData", updatedDocument);
        }
      }).catch((error) => {
        console.log('error: ', error)
      })
    }
  }

  useEffect(() => {
    if (selectedIds.length === 0) {
      getReports();
    } else {
      setDocument(selectedIds)
    }
  }, [])


  return (
    <AttributesPanelWrapper>
      <CollapseWrapper defaultActiveKey={["0", "1"]}>
        <Collapse.Item name="0" header={t("Reports")}>
          <ul style={{ listStyle: "none", userSelect: "none" }}>
            {document.map((curElem) => {
              return (
                <li key={curElem.id} className="mb-2">
                  <input className="me-2" type="checkbox" id={`report-${curElem.id}`} onChange={(event) => handleCheckbox(event, curElem.id)} checked={curElem.isCheck} style={{ cursor: "pointer" }} />
                  <label htmlFor={`report-${curElem.id}`} style={{ cursor: "pointer", fontSize: "14px" }}>
                    <img src={curElem.icon} alt="pdf-icon" width={16} height={16} className="img-fluid me-2" />
                    <span>{curElem.name}</span>
                  </label>
                </li>
              )
            })}
          </ul>
        </Collapse.Item>
        <Collapse.Item name="1" header={t("Report links styling")}>
          <ResponsiveField
            component={AttributeField.FontSize}
            path={nodePath}
            name="font-size"
          />
          <ResponsiveField
            component={AttributeField.ColorPickerField}
            path={nodePath}
            name="color"
            label={t("Link color")}
          />
          <ResponsiveField
            component={AttributeField.BackgroundColor}
            path={nodePath}
          name="background-color"
          />
          <ResponsiveField
            component={AttributeField.Padding}
            path={nodePath}
            name=""
          />
        </Collapse.Item>
      </CollapseWrapper>
    </AttributesPanelWrapper>
  );
};
