import {
  createCustomBlock,
  t,
  mergeBlock,
} from "easy-email-pro-core";
import { ElementCategory } from "easy-email-pro-core";
import React from "react";
import { getToStoreData } from "../../plugins/storage";

const defaultData = {
  attributes: {},
  data: {},
};

export const CustomBlockType = {
  STATIC_FOOTER: "static_footer"
};

export const StaticFooter = createCustomBlock({
  get name() {
    return t("Static footer");
  },
  defaultData: defaultData,
  type: CustomBlockType.STATIC_FOOTER,
  void: true,
  create: (payload) => {
    const data = {
      type: CustomBlockType.STATIC_FOOTER,
      data: {
        ...defaultData.data,
      },
      attributes: {
        ...defaultData.attributes,
      },
      children: [],
    };
    return mergeBlock(data, payload);
  },
  category: ElementCategory.UNSET,
  render(params) {
    const selectedGroup = getToStoreData('selectedGroup');

    // Function to convert ID to Base64
    function idToBase64(id) {
      return btoa(id)
    }

    return (
      <mj-section background-color="#ffffff" idx={params.idx}>
        <mj-column width="100%" >
          <mj-divider border-width="1px" border-color="#C9CCCF" padding-right="0px" padding-left="0px" ></mj-divider>
          <mj-text font-family="sans-serif, tahoma" font-size="12px" line-height="1.3" font-weight="100" padding-top="3px" padding-bottom="3px" color="#999999" align="center" >You subscribed to Vault or were added to our database by a listed company that confirmed they have your permission. Our Privacy Notice, POPIA Compliance Statement and Request for Personal Information Form can be found at&nbsp;
            <span style={{ color: "#999999", fontWeight: "600" }}><a href="https://thevault.exchange" target="_blank" rel="noreferrer">https://thevault.exchange</a>
            </span>
            <div>&nbsp;</div>
            <h6 style={{ color: "#999999", fontSize: "12px", marginTop: "0px", marginBottom: "0px", textAlign: "center" }}>
              <span>
                <a style={{ color: "#999999" }} href="https://thevault.exchange/landing/unsubscribe.php" target="_blank" rel="noreferrer">Unsubscribe entirely</a>
              </span> |
              <span>
                <a style={{ color: "#999999" }} href={`https://thevault.exchange/landing/unsubscribe.php?unsubscribe_id=${selectedGroup && idToBase64(JSON.parse(selectedGroup).id)}`} target="_blank" rel="noreferrer"> Unsubscribe from this group only</a>
              </span>
            </h6>
          </mj-text>
          <mj-divider border-width="1px" border-color="#C9CCCF" padding-right="0px" padding-left="0px" ></mj-divider>
        </mj-column>
      </mj-section>
    );
  },
});