
import React from 'react'
import GlobalNavbar from '../components/GlobalNavbar'

const NoAuth = () => {
    return (
        <>
            <GlobalNavbar />
            <div>
                <div className="cover-overlay"></div>
                <div className="pop-moal d-flex">
                    <i className="icon"> <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                    </svg></i>
                    <div>
                        <h5>You are logged out</h5>
                        <p className="mb-0"><a href={process.env.REACT_APP_LOGIN_APP_URL} style={{ color: "green" }}>Log back in</a></p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NoAuth
