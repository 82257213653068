import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import axios from '../plugins/axios';
import toast from 'react-hot-toast';
import CryptoJS from "crypto-js";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function generateRandomId(length = 6) {
  return CryptoJS.lib.WordArray.random(8) // Generate more than needed, then slice
    .toString()
    .slice(0, length); // Limit to 6 characters
}

const RSVP = () => {
  let query = useQuery();
  const data = query.get("data") || '';
  const [RSVP, setRSVP] = useState([]);
  const [queryParams, setQueryParams] = useState({});
  const [invite_id, setInvite_id] = useState(generateRandomId());


  useEffect(() => {
    if (data) {
      decodeBase64(data);
    }
  }, [data])

  const body = `Hi,

I receive alerts from the Vault investor platform and thought you might find the service useful.
You can sign up at https://thevault.exchange and join the groups of the companies you follow.

Regards
TheVault`;

  const emailLink = `mailto:athrossell@thevault.exchange?subject=Introduction to the Vault investor alerts service&body=${encodeURIComponent(body)}`;


  const updateRsvp = (index, answer, location, dietary_specifics = "") => {
    axios.post(`/rsvp`, {
      communication_invite_uuid: queryParams.communication_uuid,
      rsvp_answer: answer,
      dietary_specifics,
      user_id: queryParams.user_id,
      location: location,
      invite_id
    }).then((response) => {
      setRSVP((preRSVP) => preRSVP.map((r, ind) => {
        return ind === index ? { ...r, rsvp_answer: answer, dietary_specifics_toggle: answer !== "No" ? r.dietary_specifics_toggle : false } : r
      }))
      toast.success(response.data.message);
    }).catch((error) => {
      console.log(error, "network error");
      toast.error(error.response ? error.response.data.message : error.message)
    })
  }

  const handleDietarySpecificsChange = (index, dietary_specifics) => {
    setRSVP((preRSVP) => preRSVP.map((r, ind) => {
      return ind === index ? { ...r, dietary_specifics: dietary_specifics } : r
    }))
  }

  const handleDietarySpecificSave = (index, location) => {
    const selectedRSVP = RSVP.find((r, i) => i === index);

    updateRsvp(index, selectedRSVP.rsvp_answer, location, selectedRSVP.dietary_specifics)
  }

  // Function to decode
  const decodeBase64 = (encodedString) => {
    try {
      if (!encodedString || typeof encodedString !== 'string') {
        throw new Error('Input is not a valid string');
      }

      const decodedString = atob(encodedString);
      const decodedData = decodeURIComponent(decodedString);

      if (!decodedData.includes("&")) {
        throw new Error('Decoded string does not contain expected query parameters');
      }

      const [jsonString, ...queryParamsString] = decodedData.split("&");

      let jsonData;
      try {
        jsonData = JSON.parse(jsonString);
      } catch (parseError) {
        throw new Error('Failed to parse JSON data');
      }

      const queryParams = queryParamsString.reduce((acc, param) => {
        const [key, value] = param.split("=");
        if (key && value) {
          acc[key] = value;
        } else {
          console.warn(`Invalid query parameter: ${param}`);
        }
        return acc;
      }, {});

      // Update state with the decoded data
      setQueryParams(queryParams);
      setRSVP(jsonData);

    } catch (error) {
      console.error("Error during Base64 decoding:", error.message);
      return null;
    }
  };

  const handleDietaryPreference = (index) => {
    setRSVP((preRSVP) => preRSVP.map((r, ind) => {
      return ind === index ? { ...r, dietary_specifics: "", dietary_specifics_toggle: true } : r
    }))
  }

  return (
    <>
      <Wrapper className="container d-flex align-items-center justify-content-center py-4">
        <div className="calendar-section">
          <div className="calendar-head py-2 px-4">
            <div style={{ width: "41px" }}>
              <img src="/images/Icon-black-Vault.svg" alt="logo" className='img-fluid' />
            </div>
            <h5 className='mb-1 heading-title fw-normal'>Invitation</h5>
          </div>
          <div className='pb-3 px-4'>
            {RSVP.map((c, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="rsvp-card-body mt-3 flex-wrap">
                    <div className="rsvp-card-details">
                      <p>
                        {(c.startTime || c.endTime) &&
                          <span>{c.startTime && moment(c.startTime, "HH:mm").format("hh:mm a")}{c.endTime && " - " + moment(c.endTime, "HH:mm").format("hh:mm a")}</span>}
                        {(c.startDate || c.endDate) &&
                          <span className='ps-1'>{c.startDate && moment(c.startDate).format("Do MMMM YYYY")}{c.endDate && " - " + moment(c.endDate).format("Do MMMM YYYY")}</span>}
                      </p>
                      {c.title && <h3>{c.title.replaceAll("+", " ")}</h3>}
                      {c.details && <p className='mb-0 mt-2 text-muted small' style={{ wordBreak: "break-all" }}>{c.details.replaceAll("+", " ")}</p>}
                    </div>
                    <div>
                      <div className="rsvp-card-buttons">
                        <button className={`btn yes ${c.rsvp_answer === "Yes" ? "active" : ""}`} onClick={() => updateRsvp(index, "Yes", c?.title?.replaceAll("+", " "))}>Yes</button>
                        <button className={`btn no ${c.rsvp_answer === "No" ? "active" : ""}`} onClick={() => updateRsvp(index, "No", c?.title?.replaceAll("+", " "))}>No</button>
                      </div>
                    </div>
                  </div>
                  {c.dietary_specifics_toggle && <>
                    <div className="dietary-preference mt-3">
                      <label htmlFor="preference" className="rsvp-card-label">Dietary preference:</label>
                      <select id="preference" className="rsvp-dropdown form-select" value={c.dietary_specifics || ""} onChange={(e) => handleDietarySpecificsChange(index, e.target.value)}>
                        <option value="">----</option>
                        <option value="None">None</option>
                        <option value="Vegetarian">Vegetarian</option>
                        <option value="Fish only">Fish only</option>
                        <option value="Kosher">Kosher</option>
                        <option value="Halal">Halal</option>
                      </select>
                      <div>
                        <button className="btn btn-Vault mt-2 px-3 py-1" onClick={() => handleDietarySpecificSave(index, c?.title?.replaceAll("+", " "))}>Save</button>
                      </div>
                    </div>
                  </>}
                  {c.rsvp_answer === "Yes" && !c.dietary_specifics_toggle &&
                    <div className="dietary-preference mt-3">
                      <h4>Your dietary preference</h4>
                      <p>
                        <NavLink to="#" className="dietary-preference-link" onClick={() => handleDietaryPreference(index)}>
                          If you are attending a physical event, please select your dietary preference.
                        </NavLink>
                      </p>
                    </div>}
                </React.Fragment>
              )
            })}
            <footer className="rsvp-card-footer my-3 pt-3">
              <a href={emailLink}><i className="fa-solid fa-user-group"></i> Would you like to add a colleague to The Vault?</a>
              <a href='mailto:athrossell@thevault.exchange'> <i className="fa-regular fa-envelope"></i> Drop us an email should you need assistance</a>
            </footer>
          </div>
        </div>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
height: 100vh;
  .calendar-head{
    background-color: #f7f7f7;
    border-radius: 10px 10px 0 0;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .calendar-head p{
    color: #8a959b;
    font-size: 15px;
  }

  .heading-title{
    color: #2f2f2f;
  }

  .calendar-section{
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 560px;
  }

  .rsvp-card-body {
    display: flex;
    /* align-items: center;  */
    justify-content: space-between;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
  }

  .rsvp-card-details {
    flex: 1;
    text-align: left;
  }

  .rsvp-card-details p {
    margin: 0;
    color: #a9a9a9;
    font-size: 14px;
  }

  .rsvp-card-details h3 {
    margin-top: 8px;
    font-size: 16px;
    font-weight: bold;
  }
  .rsvp-card-label {
    font-size: 14px;
    color: #000000;
    font-weight: 600;
    display: block;
  }

  .rsvp-dropdown {
    width: 50%;
    padding: 8px;
    font-size: 14px;
    color: #333;
    border: 2px solid #4CAF50; /* Green border */
    border-radius: 20px;
    outline: none;
    background-color: #fff;
    text-align: center;
    cursor: pointer;
    margin-top: 8px;
  }

  .rsvp-dropdown option {
    color: #333;
  }

  .btn-vault{
    font-weight: 500 !important;
    letter-spacing: 0.5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .rsvp-card-buttons {
    display: flex;
    border: 1px solid #606060;
    border-radius: 6px;
    overflow: hidden;
    margin-top: 8px;
  }

  .rsvp-card-buttons .btn {
    border: none;
    background: transparent;
    font-weight: 600;
    padding: 5px 8px;
    cursor: pointer;
    font-size: 14px;
    width: 50px;
    text-align: center;
    color: #606060;
    position: relative;
    border-radius: unset;
  }

  .rsvp-card-buttons .btn.active{
    background-color: #606060;
    color: #ffff;
  }

  .rsvp-card-buttons .btn.yes::after {
    content: "";
    position: absolute;
    top: 4px; 
    bottom: 4px;
    right: 0;
    width: 1px;
    background-color: #606060;
  }

  .dietary-preference {
    padding: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
  }

  .dietary-preference h4 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    color: #333;
  }

  .dietary-preference p {
    margin: 8px 0 0 0;
  }

  .dietary-preference-link {
    font-size: 14px;
    color: rgb(39 80 65); /* Dark green */
  }

  .dietary-preference-link:hover {
    text-decoration: none;
  }

  .rsvp-card-footer {
    border-top: 1px solid #c7c7c7;
  }

  .rsvp-card-footer  a{
    color: #131313;
    text-decoration: none;
    /* color: #696969; */
    font-size: 15px;
    display: inline-block;
    font-weight: 600;
  }

  @media (max-width: 991px) {
    .rsvp-card-details{
      flex: unset;
    }
  }
`

export default RSVP
