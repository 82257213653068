import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/main.css';
import './assets/font-awesome/css/all.min.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import routes from './router/Routes';
import 'bootstrap/dist/css/bootstrap.css';
import { Toaster } from 'react-hot-toast';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <RouterProvider router={routes} />
    <Toaster
      position="top-right"
      reverseOrder={false}
      toastOptions={{
        duration: 5000,
        success: {
          duration: 3000,
          theme: {
            primary: 'green',
          },
        },
      }}
    />
  </>
);
reportWebVitals();
