import { Collapse } from "@arco-design/web-react";
import { t } from "easy-email-pro-core";
import React, { useMemo } from "react";
import { AttributesPanelWrapper, CollapseWrapper, AttributeField, ResponsiveField, ResponsiveTabs, useEditorContext, } from "easy-email-pro-theme";
import { ActiveTabKeys, useSelectedNode } from "easy-email-pro-editor";

export const buttonWidthAdapter = {
  formatter(val) {
    return val === "100%" ? "100%" : "auto";
  },
  normalize(val) {
    return val === "100%" ? "100%" : undefined;
  },
};

export const CustomRSVPBlockPanel = ({ nodePath }) => {
  return (
    <AttributesPanelWrapper>
      <ResponsiveTabs
        desktop={
          <AttributesContainer
            mode={ActiveTabKeys.DESKTOP}
            nodePath={nodePath}
          />
        }
        mobile={
          <AttributesContainer
            mode={ActiveTabKeys.MOBILE}
            nodePath={nodePath}
          />
        }
      />
    </AttributesPanelWrapper>
  );
};

function AttributesContainer({
  nodePath,
  mode,
}) {
  const { selectedNode } = useSelectedNode();
  const { setFieldValue } = useEditorContext();

  const RSVPData = useMemo(
    () => selectedNode?.data.RSVPData || [],
    [selectedNode]
  );
  const webcastEventToggle = useMemo(
    () => selectedNode?.data.webcastEventToggle || false,
    [selectedNode]
  );

  if (!selectedNode) return null;
  const addLocation = () => {
    const updatedDocument = [...RSVPData, { location: "", startDate: "", startTime: "", endDate: "", endTime: "", details: "" }];
    setFieldValue(nodePath, "data.RSVPData", updatedDocument);
  };

  const handleDeleteLocation = (index) => {
    const updatedDocument = RSVPData.filter((_, ind) => ind !== index);
    setFieldValue(nodePath, "data.RSVPData", updatedDocument);
  }

  const handleWevCastEvent = (e) => {
    setFieldValue(nodePath, "data.webcastEventToggle", e.target.checked);
  }

  return (
    <CollapseWrapper defaultActiveKey={["0", "1"]}>
      <Collapse.Item name="0" header={t("Button")}>
        <AttributeField.TextField
          path={nodePath}
          name="data.content"
          label={t("Button Text")}
        />
        {/* <AttributeField.Link path={nodePath} name="attributes.href" /> */}
      </Collapse.Item>
      <Collapse.Item name="1" header={t("RSVP Details")}>
        <div className="form-check fs-6 mb-3">
          <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={webcastEventToggle} onChange={handleWevCastEvent} />
          <label className="form-check-label fw-bold" for="flexCheckDefault">
            Hide Dietary preferences (for webcast event)
          </label>
        </div>
        {RSVPData.map((data, index) => (
          <React.Fragment key={index}>
            {index !== 0 && <>
              <hr />
              <div className="text-end pe-3">
                <span role="button" className="iconfont icon-remove-cycle block-list-grid-item-icon fw-bold" onClick={() => handleDeleteLocation(index)}></span>
              </div>
            </>}
            <AttributeField.TextField
              label={t("Webcast Venue")}
              name={`data.RSVPData[${index}].title`}
              path={nodePath}
              value={data.title || ""}
              placeholder="Title"
              className="form-control"
            />
            <AttributeField.TextField
              label={t("Start date")}
              name={`data.RSVPData[${index}].startDate`}
              path={nodePath}
              value={data.startDate || ""}
              className="form-control"
              type="date"
            />
            <AttributeField.TextField
              label={t("Start time")}
              name={`data.RSVPData[${index}].startTime`}
              path={nodePath}
              value={data.startTime || ""}
              className="form-control"
              type="time"
            />
            <AttributeField.TextField
              label={t("End date")}
              name={`data.RSVPData[${index}].endDate`}
              path={nodePath}
              value={data.endDate || ""}
              className="form-control"
              type="date"
            />
            <AttributeField.TextField
              label={t("End time")}
              name={`data.RSVPData[${index}].endTime`}
              path={nodePath}
              value={data.endTime || ""}
              className="form-control"
              type="time"
            />
            <AttributeField.TextAreaField
              label={t("Details")}
              name={`data.RSVPData[${index}].details`}
              path={nodePath}
              value={data.details || ""}
              className="form-control d-block"
              cols={5}
              rows={5}
            />
          </React.Fragment>
        ))}
        <hr />
        <div className="container">
          <button type="button" onClick={addLocation} className="btn add-location-btn">
            <span className="iconfont block-list-grid-item-icon icon-add-cycle me-2"></span>
            {t("Add another location")}
          </button>
        </div>
      </Collapse.Item>
      <Collapse.Item name="2" header={t("Dimension")}>
        <ResponsiveField
          label={t("Width")}
          component={AttributeField.ButtonGroupField}
          path={nodePath}
          name="width"
          options={[
            { label: t("Auto"), value: "auto" },
            { label: t("Full width"), value: "100%" },
          ]}
          formItem={buttonWidthAdapter}
        />
        <ResponsiveField
          component={AttributeField.Height}
          path={nodePath}
          name="height"
        />
        <ResponsiveField
          label={t("Inner padding")}
          fieldName="inner-padding"
          component={AttributeField.Padding}
          path={nodePath}
          name=""
        />
        <ResponsiveField
          component={AttributeField.Padding}
          path={nodePath}
          name=""
        />
      </Collapse.Item>
      <Collapse.Item name="3" header={t("Typography")}>
        <ResponsiveField
          mode={mode}
          component={AttributeField.Buttons}
          path={nodePath}
          name=""
          type={selectedNode.type}
          hidePadding
        />
      </Collapse.Item>
      <Collapse.Item name="4" header={t("Border")}>
        <ResponsiveField
          component={AttributeField.Border}
          path={nodePath}
          name=""
        />
        <ResponsiveField
          component={AttributeField.BorderRadius}
          path={nodePath}
          name="border-radius"
        />
      </Collapse.Item>
      <Collapse.Item name="5" header={t("Block")}>
        <ResponsiveField
          component={AttributeField.BackgroundColor}
          path={nodePath}
          name="container-background-color"
        />
        <ResponsiveField
          component={AttributeField.TextAlign}
          path={nodePath}
          name={`align`}
        />
      </Collapse.Item>
    </CollapseWrapper>
  );
}
