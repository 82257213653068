import moment from 'moment';
import React from 'react'
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { saveAs } from "file-saver";
import ical from 'ical-generator';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Calendar = () => {
  let query = useQuery();

  const handleSave = () => {
    const start = query.get("startDate") + " " + query.get("startTime")
    const end = query.get("endDate") + " " + query.get("endTime")

    // Create a new iCal object
    const calendar = ical();

    // Add event to the calendar
    calendar.createEvent({
      start: start,
      end: end,
      summary: query.get("title"),
      description: query.get("details"),
    });

    // Generate the .ICS file content
    const content = calendar.toString();

    const blob = new Blob([content], { type: "text/plain;charset=utf-8" });

    saveAs(blob, "event-schedule.ics");
  };

  const title = query.get("title");
  const details = query.get("details");
  const start = new Date(query.get("startDate") + " " + query.get("startTime"));
  const end = new Date(query.get("endDate") + " " + query.get("endTime"));

  const dateFormatChange = (date) => {
    const formattedDate = moment(date).format('YYYYMMDDTHHmmss') + 'Z';

    return formattedDate;
  }

  const body = `Hi,

I receive alerts from the Vault investor platform and thought you might find the service useful.
You can sign up at https://thevault.exchange and join the groups of the companies you follow.

Regards
TheVault`;

  const emailLink = `mailto:athrossell@thevault.exchange?subject=Introduction to the Vault investor alerts service&body=${encodeURIComponent(body)}`;

  return (
    <Wrapper className="container d-flex align-items-center justify-content-center">
      <div className="calendar-section">
        <div className="calendar-head py-2 px-4">
          <div style={{ width: "41px" }}>
            <img src="/images/Icon-black-Vault.svg" alt="logo" className='img-fluid' />
          </div>
          <h5 className='mb-0 heading-title'>The Vault</h5>
        </div>
        <div className='pt-2 pb-3 px-4'>
          <h5 className='mt-4 mb-3'>Save the date</h5>
          <div className='calendar-info'>
            <h5 className='my-3 fw-normal'>{query.get("title")}</h5>
            <p className='mb-0'>
              <i className="fa-regular fa-calendar pe-2"></i>
              <span>{moment(query.get("startDate")).format("Do MMMM YYYY") + " - " + moment(query.get("endDate")).format("Do MMMM YYYY")} </span>
            </p>
            <p className='mb-0'>
              <i className="fa-regular fa-clock pe-2"></i>
              <span>{moment(query.get("startTime"), "HH:mm").format("hh:mm a") + " - " + moment(query.get("endTime"), "HH:mm").format("hh:mm a")}</span>
            </p>
            <p className='mb-0'>
              <i className="fa-solid fa-location-dot pe-2"></i>
              <span className='ps-1' style={{ wordBreak: "break-all" }}>{query.get("details")}</span>
            </p>
            <h6 className='mb-1 pt-3'>Add to calendar</h6>
            <h6 className='download-link'>Download:
              <button className='ms-1 me-2' onClick={handleSave}>
                <img src="/images/ics-icon.png" alt="ics-icon" className='img-fluid' width={25} height={25} />
              </button>
              <a target="_blank" rel="noreferrer"
                href={`https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${dateFormatChange(start)}/${dateFormatChange(end)}&details=${details}&text=${title}&reminders=[{"method":"none"}]`} className="text-decoration-none"
              >
                <img src="/images/google-calendar.png" alt="google-calendar-icon" className='img-fluid' width={25} height={25} />
              </a>
            </h6>
          </div>
          <div className='calendar-footer py-3'>
            <a href={emailLink} className='mb-1'><i className="fa-solid fa-people-group"></i> Would you like to add a colleague to The Vault</a>
            <a href='mailto:athrossell@thevault.exchange'><i className="fa-regular fa-envelope"></i> Drop us an email should you need assistance</a>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100vh;

  .calendar-head{
    background-color: #92edce;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 10px 10px 0 0;
  }

  .heading-title{
    font-weight: 400 !important;
  }

  .calendar-section{
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 460px;
  }

  .calendar-info{
    padding: 0 15px 15px;
    border: 2px solid #aef2db;
  }

  .calendar-info i{
    font-size: 14px;
  }

  .calendar-info span, .calendar-footer p{
    color: #696969;
    font-size: 14px;
  }
  
  .calendar-footer a{
    text-decoration: none;
    color: #696969;
    font-size: 16px;
    display: inline-block;
  }
  
  .download-link{
    font-weight: 400;
  }

  .download-link button{
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0px;
  }
`

export default Calendar
