import CryptoJS from 'crypto-js'

// get store data
export const getToStoreData = (name) => {
    const encrypted = sessionStorage.getItem(name);
    if (encrypted) {
        const decrypted = CryptoJS.AES.decrypt(encrypted, "secret key 123").toString(CryptoJS.enc.Utf8);
        return decrypted;
    } else {
        return ""
    }
}

// set store data
export const addToStoreData = (name, data) => {
    const ciphertext = CryptoJS.AES.encrypt(data, 'secret key 123').toString()
    return sessionStorage.setItem(name, ciphertext);
}

//  remove for store data
export const removeToStoreData = (name) => {
    return sessionStorage.removeItem(name);
}
//  clear for store data
export const clearToStoreData = () => {
    return sessionStorage.clear();
}
