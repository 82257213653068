import React from 'react'

const SuccessModal = ({ successMessage }) => {
  return (
    <div className="modal fade" id="success-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
          </div>
          <div className="modal-body my-1 d-flex gap-2">
            <div className="check-icon">
              <img src="/images/success-1.png" alt="check_icon" className="img-fluid" />
            </div>
            <div>
              <h5 id='success-message'>{successMessage}</h5>
              <p className="mb-0">Please give it a few seconds.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuccessModal;
