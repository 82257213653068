import React from 'react';
import styled from 'styled-components';
import { addToStoreData, removeToStoreData } from '../plugins/storage';
import { useNavigate } from 'react-router-dom';

const TemplateCard = ({ data }) => {

  const history = useNavigate();

  const handleUseTemplate = () => {
    removeToStoreData("communication_uuid");
    addToStoreData("communicate_data", JSON.stringify(data));
    history('/editor');
  }

  return (
    <Wrapper className='px-3'>
      <div className="card" onClick={handleUseTemplate}>
        <img src={data.template_image} className="card-img-top img-fluid" alt="template_image" />
        <div className="overlay">
          <div className='hover-info'>
            {/* <p className='preview-link mb-2' onClick={handleUseTemplate}>Preview</p> */}
            <button className="hover-button">Use template</button>
          </div>
        </div>
      </div>
      <h5 className="card-title text-center py-3">{data.title}</h5>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .card-title{
    color: #2d5948;
    font-weight: normal;
    letter-spacing: 0.3px;
  }

  .card {
    border-radius: 15px !important;
    position: relative;
    cursor: pointer;
    /* height: 500px;
    overflow: auto; */
  }

  .card img{
    max-height: 500px;
    max-width: 100% !important;
    width: fit-content;
    margin: 0 auto;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 15px;
    z-index:10;
  }

  .hover-info {
    position: absolute;
    top: 70%;
    text-align: center;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    color: white;
  }

  .hover-button{
    padding: 6px 12px;
    background-color: #70efcc;
    color: #2d5948;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    text-transform: capitalize;
  }

  .card:hover .overlay {
    transition: all 0.3s linear;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 1;
  }
  
  
  .card:hover .hover-info {
    transition: all 0.3s linear;
    display: block;
  }

  .card-img-top{
    border-radius: 15px !important;
  }

  .preview-link{
    cursor: pointer;
  }
`

export default TemplateCard;
