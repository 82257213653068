import { useCallback } from "react";
import { getToStoreData } from "../../plugins/storage";
import toast from "react-hot-toast";

export function useUpload() {
  const upload = useCallback(async (file) => {
    if (!file) return;
    const selectGroup = getToStoreData('selectedGroup');

    if (file.size > 1024 * 1024 * 6) {
      toast.error(`File is too large. Please select a file smaller than 6MB.`);
      throw new Error(`File size too large`);
    }

    const formData = new FormData();
    formData.append('image', file);

    if (selectGroup) {
      const groupId = JSON.parse(selectGroup).id;
      formData.append('group_id', groupId);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/upload`, {
        method: 'POST',
        body: formData,
      });

      // Check if the response status is 413 (Content Too Large)
      if (response.status === 413) {
        toast.error("File is too large. Please select a file smaller than 6MB.");
        throw new Error(`File size too large`);
      }

      const result = await response.json();
      if (result.url) {
        return Promise.resolve(result.url);
      }
    } catch (error) {
      console.log('Network error:', error);
      toast.error("An error occurred in the upload. Please try again later.")
      throw new Error(error);
    }
  }, []);

  return {
    upload
  };
}
