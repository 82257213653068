import React, { useEffect, useState } from 'react';
import { getToStoreData, removeToStoreData } from '../plugins/storage';
import GlobalNavbar from '../components/GlobalNavbar';
import TemplateCard from '../components/TemplateCard';
import styled from 'styled-components';
import { NavLink, useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from '../plugins/axios';

const TemplateList = () => {

  const [loggedInMessage, setLoggedInMessage] = useState("");
  const [templateData, setTemplateData] = useState([]);
  const history = useNavigate();

  const getTemplateData = () => {
    axios.get("/templates").then(async(response) => {
      if (response.data.success) {
        setTemplateData(response.data.data);
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  // Get for selected group name of local storage
  useEffect(() => {
    getTemplateData();
    const selectedGroupData = getToStoreData("selectedGroup");

    if (selectedGroupData) {
      const message = "You are logged in to Communicator";
      setLoggedInMessage(message + ' Editing Group: ' + JSON.parse(selectedGroupData).name);
    }
  }, []);

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const redirectToEditor = () => {
    removeToStoreData("communicate_data");
    removeToStoreData("communication_uuid");
    history('/editor');
  }

  const handleHomeBack = () => {
    history("/communicate");
  }
  return (
    <Wrapper>
      <GlobalNavbar LoggedIn={loggedInMessage} />
      <section className='container mx-auto py-4'>
        <div className="row">
            <div className="col-md-10">
              <h3 className="templates">Templates</h3>
            </div>
            <div className="col-md-2">
              <div className="create-btn py-1 mt-1 mb-4">
                <span onClick={handleHomeBack}>&times;</span>
              </div>
            </div>
        </div>
        {templateData.length > 0 &&
        <div>
          <Slider {...settings}>
            {templateData.map((curElem, index) => {
              return <TemplateCard data={curElem} key={index} />
            })}
          </Slider>
        </div>}
      </section>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  .create-btn button{
    background-color: white;
    border: none;
    outline: none;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px 30px;
    border-radius: 7px;
    letter-spacing: 0.5px;
    color: #4a4a4a;
    text-decoration: none;
    font-size: 16px;
  }
  .slick-prev, .slick-next{
    background-color: #92edce !important;
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
`

export default TemplateList;