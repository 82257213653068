import { createCustomBlock, t, mergeBlock } from "easy-email-pro-core";
import { ElementCategory } from "easy-email-pro-core";

export const CustomHeroType = {
  LOGO: "Custom Hero",
};

const defaultData = {
  data: {},
  attributes: {}
};

export const CustomHero = createCustomBlock({
  get name() {
    return t("Custom Hero");
  },
  defaultData: defaultData,
  type: CustomHeroType.LOGO,
  void: true,
  create: (payload) => {
    const data = {
      type: CustomHeroType.LOGO,
      data: {
        ...defaultData.data,
      },
      attributes: {
        ...defaultData.attributes,
      },
      children: [],
    };
    return mergeBlock(data, payload);
  },
  category: ElementCategory.HERO
});